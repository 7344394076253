/** Displays the image in a new window and issues a browser print command
 * @param blob - image blob to print
 * @param title - title of the new browser window */
const printBlobImage = (blob: Blob, title: string = 'Print Image') => {
  /* As URL.createObjectURL ties the image to the document of the current window
   * We must use a file reader to get the contents of the image */
  const reader = new window.FileReader();
  reader.onloadend = function () {
    const base64data = reader.result;

    if (!base64data) {
      return;
    }

    printImageInNewWindow(base64data.toString(), title);
  }

  reader.readAsDataURL(blob);
}

const printImageInNewWindow = (imageSrc: string, title: string) => {
  const tempHtml = `
      <html>
        <head>
          <title>${title}</title>
          <script>
            window.onload = function() {
                window.print();
              };
          </script>
        </head>
        <body>
          <img src="${imageSrc}" />
        </body>
      </html>
    `;
  const tempWindow = window.open("about:blank", "_blank");
  tempWindow?.document.write(tempHtml);
  tempWindow?.document.close();
}

/** Copies the passed blob to the clipboard */
const copyBlobImageToClipboard = async (blob: Blob, isPng: boolean) => {
  if (!isPng) {
    /* As only copying png files to the clipboard is supported by all browsers
     * We must convert the blob to a png blob first.
     * See https://frontendmasters.com/blog/writing-to-the-clipboard-in-javascript/
     */
    const pngBlob = await convertImageBlobToPngBlob(blob);

    if (!pngBlob) {
      return;
    }

    blob = pngBlob;
  }

  navigator.clipboard.write([
    new ClipboardItem({
      'image/png': blob
    })
  ]);
}

const convertImageBlobToPngBlob = async(blob: Blob) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return undefined;
  }

  // Convert blob to a bitmap image so we can set the correct height and width of the canvas
  const bitmap = await createImageBitmap(blob)
  canvas.width = bitmap.width;
  canvas.height = bitmap.height;

  ctx.drawImage(bitmap, 0, 0);

  const pngBlob = await new Promise(resolve => canvas.toBlob(resolve, 'image/png'));

  // We must cast pngBlob to a Blob type, otherwise Typescript will cause compile errors
  return pngBlob as Blob;
}

export { printBlobImage, copyBlobImageToClipboard }